import React, {useContext} from 'react';
//import {useHistory} from 'react-router-dom';

import DocumentIcon from '../../../assets/document.webp';

import './ProductAttachment.scss';
import {I18nContext, T} from "@deity-io/falcon-i18n";
//const atob = (s) => Buffer.from(s, 'base64').toString()
/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
function humanFileSize(bytes, si=false, dp=1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }

    const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10**dp;

    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


    return bytes.toFixed(dp) + ' ' + units[u];
}

const ProductAttachment = ({product: {attachments, uid}}) => {

    const {t} = useContext(I18nContext);
    // TODO: More icons
    //const history = useHistory(); //TODO: Maybe rework this
    if (attachments === undefined || attachments.length === 0) {
        return (
            <ul className="product-declaration">
                <div className="product-declaration__no-declaration">
                    <T id="product.noAttachments" />
                </div>
            </ul>
        );
    }
    return (
        <div className="product-attachment">
            <div className="product-attachment__files">
                {attachments.filter(({label})=> {
                    return label != 'gpsr'
                }).map(({frontend_url, link, file_id, filename = "File", filepath, size, label}) => (
                    <div
                        key={file_id}
                        onClick={() => window.open(link ? link :  frontend_url)}
                        className="product-attachment__file"
                    >
                        <img src={DocumentIcon} alt=""/>
                        <div className="product-attachment__filename"> {t(label)}</div>
                        <div>({humanFileSize(size, false, 2)})</div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default React.memo(ProductAttachment);
