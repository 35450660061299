import React, {memo, useCallback, useState} from 'react';
import clsx from 'clsx';
import {T} from '@deity-io/falcon-i18n';
import ProductDescription from '../ProductDescription/ProductDescription';
import ProductDelivery from '../ProductDelivery/ProductDelivery';
import ProductDeclaration from '../ProductDeclaration/ProductDeclaration';
import ProductAttachment from '../ProductAttachment/ProductAttachment';
import useResponsive from '../../../hooks/useResponsive';

import './ProductTabs.scss';
import Products from "../../../components/Category/Products/Products"
import Gpsr from "../../Gpsr/Gpsr";
import readConfig from "../../../utils/readClientConfiguration";
import appConfiguration from "../../../../config/default.json";


const ProductTabs = ({ product }) => {
  const { isMobile } = useResponsive();

  const DESCRIPTION_TAB = 1;
  const DELIVERY_TAB = 2;
  const DECLARATION_TAB = 3;
  const ATTACHMENTS_TAB = 4;
  const INSPIRATIONS_TAB = 5;
  const GPSR_TAB = 6;

  const gpsrConfig = readConfig(appConfiguration, 'gpsr');
  const gpsrEnabled = gpsrConfig.enabled;
  const {
    inspirations,
    attachments,
    storefront_attributes: { declarationAttributes },
  } = product;

  const [tabState, setOpenTab] = useState({
    opened: DESCRIPTION_TAB,
  });

  const setTab = useCallback(activeTab => {
    setOpenTab({ opened: activeTab });
  }, []);

  return (
    <>
      <div className="product-tabs" id="productTabs">
        <div
            className={clsx('product-tabs__tab', tabState.opened === DESCRIPTION_TAB && 'product-tabs__tab_active')}
            onClick={() => setTab(DESCRIPTION_TAB)}
        >
          <T id="product.descriptionTab"/>
        </div>
        {isMobile && tabState.opened === DESCRIPTION_TAB && (
            <div className="product-tabs__mobile">
              <ProductDescription tabId={ATTACHMENTS_TAB} setTab={setTab} product={product}/>
            </div>
        )}
        <div
            className={clsx('product-tabs__tab', tabState.opened === DELIVERY_TAB && 'product-tabs__tab_active')}
            onClick={() => setTab(DELIVERY_TAB)}
        >
          <T id="product.deliveryTab"/>
        </div>
        {isMobile && tabState.opened === DELIVERY_TAB && (
            <div className="product-tabs__mobile">
              <ProductDelivery product={product}/>
            </div>
        )}
        {declarationAttributes.length > 0 && <div
            className={clsx('product-tabs__tab', tabState.opened === DECLARATION_TAB && 'product-tabs__tab_active')}
            onClick={() => setTab(DECLARATION_TAB)}
        >
          <T id="product.declarationTab"/>
        </div>
        }


        {isMobile && tabState.opened === DECLARATION_TAB && (
            <div className="product-tabs__mobile">
              <ProductDeclaration product={product}/>
            </div>
        )}
        {attachments.length > 0 && <div
            className={clsx('product-tabs__tab', tabState.opened === ATTACHMENTS_TAB && 'product-tabs__tab_active')}
            onClick={() => setTab(ATTACHMENTS_TAB)}
        >
          <T id="product.attachmentTab"/>
        </div>
        }
        {isMobile && tabState.opened === ATTACHMENTS_TAB && (
            <div className="product-tabs__mobile">
              <ProductAttachment product={product}/>
            </div>
        )}

        {inspirations.length > 0 && <div
            className={clsx('product-tabs__tab', tabState.opened === INSPIRATIONS_TAB && 'product-tabs__tab_active')}
            onClick={() => setTab(INSPIRATIONS_TAB)}
        >
          <T id="product.inspirations"/>
        </div>
        }

        {gpsrEnabled && <div
                className={clsx('product-tabs__tab', tabState.opened === GPSR_TAB && 'product-tabs__tab_active')}
                onClick={() => setTab(GPSR_TAB)}
            >
              <T id="gpsr.product_safety"/>
            </div>
        }
        {gpsrEnabled && isMobile && tabState.opened === GPSR_TAB && (
            <div className="product-tabs__mobile">
              <Gpsr product={product}/>
            </div>
        )}


        {isMobile && tabState.opened === INSPIRATIONS_TAB && (
            <div className="product-tabs__mobile">
              <Products items={inspirations}/>
            </div>
        )}

      </div>


      <div className="product-tab-content">
        {!isMobile && tabState.opened === DESCRIPTION_TAB && <ProductDescription product={product} setTab={setTab}/>}
        {!isMobile && tabState.opened === DELIVERY_TAB && <ProductDelivery/>}
        {!isMobile && tabState.opened === DECLARATION_TAB && <ProductDeclaration product={product} />}
        {!isMobile && tabState.opened === ATTACHMENTS_TAB && <ProductAttachment product={product} />}
        {!isMobile && tabState.opened === INSPIRATIONS_TAB && <Products items={inspirations}/>}
        {gpsrEnabled && !isMobile && tabState.opened === GPSR_TAB && <Gpsr product={product}/>}
      </div>
    </>
  );
};

export default memo(ProductTabs);
